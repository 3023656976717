<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-12">
                    <h3>About Us</h3>
                    <div class="mt-25">
                        <p class="mt-25">
                            S4L Consult is a security company that provides
                            security for individuals, corporate institutions,
                            the private and public sector and consulting
                            services.
                        </p>
                        <p class="mt-25">
                            Making security everyone’s responsibility.
                        </p>
                    </div>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                    <h3>Quick Links</h3>
                    <ul class="footer-list">
                        <li>
                            <router-link to="/about-us">About Us</router-link>
                        </li>
                        <li><router-link to="/team">Team</router-link></li>
                        <li>
                            <router-link to="/services">Services</router-link>
                        </li>
                        <li>
                            <router-link to="/contact-us"
                                >Contact Us</router-link
                            >
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-sm-6 col-12">
                    <h3>Social</h3>
                    <div class="footer-social-icons mt-25">
                        <ul>
                            <li>
                                <a
                                    href="https://web.facebook.com/s4lconsult/"
                                    target="_blank"
                                >
                                    <i class="fa fa-facebook-square"></i>
                                    <span>Facebook</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/s4lconsult/"
                                    target="_blank"
                                >
                                    <i class="fa fa-instagram"></i>
                                    <span>Instagram</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/s4lconsult/"
                                    target="_blank"
                                >
                                    <i class="fa fa-twitter"></i>
                                    <span>Twitter</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer-bar">
                <p>© S4L Consult {{ year }}. All Rights Reserved.</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {
            year: new Date().getFullYear(),
        };
    },
};
</script>